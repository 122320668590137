// **************LocalHost******************

// export const domain = "http://localhost:4000";
// export const wssdomain = "ws://localhost:4000";

// **************Production******************

// export const domain = 'https://aviatorbet.online';
// export const wssdomain = 'wss://aviatorbet.online';

// **************Production2******************

// export const domain = 'https://demo1.anotechsolutions.cloud';
// export const wssdomain = 'wss://demo1.anotechsolutions.cloud';

// **************Production3******************


 export const domain = 'https://z1club.in';
 export const wssdomain = 'wss://z1club.in';